import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { MPageTitle } from '../../shared/page-title'
import { BooleanSetting } from '../../shared/settings'

export const GreenhouseSettingsPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet title={`Greenhouse App - ${t('Settings')}`} />

      <MPageTitle label={`Greenhouse App - ${t('Settings')}`} />

      <br />

      <BooleanSetting
        settingName='use-new-phase-date-format'
        displayName={t('Use new Phase date format')}
        explanation={t('Use new date format for Job Production Phases. Only enable for compatible BC versions.')}
      />

      <BooleanSetting
        settingName='enable-work-order-comments'
        displayName={t('Enable Work Order comments')}
        explanation={t(
          'Allow users to record comments on Tasks which will be synchronized back on the Job within Business Central.',
        )}
      />

      <BooleanSetting
        settingName='enable-decimal-registration'
        displayName={t('Enable decimal registration')}
        explanation={t('Allow users to enter decimal numbers where the "Containers" Unit of Measure is used.')}
      />

      <BooleanSetting
        settingName='enable-percentage-registration'
        displayName={t('Enable registering in percentages')}
        explanation={t('Allow users to register quantities in percentages.')}
      />

      <BooleanSetting
        settingName='enable-position-navigation-buttons'
        displayName={t('Enable Position navigation buttons')}
        explanation={t(
          'Adds arrow buttons to the "To Position" field on the Job Mutations page to navigate through the positions.',
        )}
      />
    </>
  )
}
