import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js'
import { Field, Formik } from 'formik'
import { MutationResult, useMutation, useQuery } from '@apollo/client'
import { CircularProgress, IconButton, Switch } from '@mui/material'
import { MFlexBlock, MFieldInteger, MText, MTextColor } from '@mprise/react-ui'
import { useCurrentCompanyId } from './useCurrentCompany'
import { MutationErrorMessage, QueryErrorMessage } from './react-apollo'
import { GET_BOOLEAN_SETTING, GET_INT_SETTING, SET_BOOLEAN_SETTING, SET_INT_SETTING } from '../gql/settings'
import { withFormikCompareFix } from './formik'
import { MFieldConnector } from './mfield-adapter'
import { FormikDialog } from './dialog/react-formik-dialog'
import { useLocalState } from './react-local-state'
import { SavingSwitchPanel } from './saving-switch-panel'

export const BooleanSetting = ({
  settingName,
  displayName,
  explanation,
}: {
  settingName: string
  displayName: string
  explanation?: string
}) => {
  const companyId = useCurrentCompanyId()

  const [settingEnabled, setSettingEnabled] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_BOOLEAN_SETTING, {
    variables: {
      companyId: +companyId,
      settingName,
    },
  })

  const [setSetting, { data: setSettingData, loading: saving, error: setSettingError }] =
    useMutation(SET_BOOLEAN_SETTING)

  useEffect(() => {
    setSettingEnabled(data?.booleanSetting ?? false)
  }, [data])

  useEffect(() => {
    const newSettingValue = setSettingData?.setBooleanSetting
    if (newSettingValue != null) {
      setSettingEnabled(newSettingValue)
    }
  }, [setSettingData])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSettingValue = event.target.checked
    setSettingEnabled(newSettingValue)

    setSetting({
      variables: {
        companyId: +companyId,
        settingName,
        value: newSettingValue,
      },
    })
  }

  return (
    <MFlexBlock vertical gap={2} margin={[0, 4, 4, 4]} padding={[0, 2]}>
      <QueryErrorMessage query={{ loading, error, refetch }} />
      <MutationErrorMessage mutation={{ error: setSettingError } as MutationResult} />
      <MText block textVariant='content' textColor='#000000de'>
        {displayName}
      </MText>
      <MText block textVariant='small' textColor={MTextColor.shadow}>
        {explanation}
      </MText>
      <MFlexBlock gap={2} alignItems='center' margin={[-1, 0, 2, -2]}>
        <Switch checked={settingEnabled} disabled={loading || saving} onChange={handleChange} />
        {(loading || saving) && <CircularProgress size='20px' />}
      </MFlexBlock>
    </MFlexBlock>
  )
}

export const IntegerSetting = ({
  settingName,
  displayName,
  explanation,
}: {
  settingName: string
  displayName: string
  explanation?: string
}) => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const [settingValue, setSettingValue] = useState(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_INT_SETTING, {
    variables: {
      companyId: +companyId,
      settingName,
    },
  })

  const [updateSetting, updateSettingState] = useMutation(SET_INT_SETTING)

  useEffect(() => {
    setSettingValue(data?.intSetting)
  }, [data])

  const handleDialogSave = async (form: { value: number }) => {
    if (!loading && form.value && form.value !== settingValue) {
      await updateSetting({
        variables: {
          companyId: +companyId,
          settingName,
          value: form.value,
        },
      })
      refetch()
    }
    setEditDialogOpen(false)
  }

  return (
    <>
      <MFlexBlock vertical gap={2} margin={[0, 4, 4, 4]} padding={[0, 2]}>
        <QueryErrorMessage query={{ loading, error, refetch }} />
        <MText block textVariant='content' textColor='#000000de'>
          {displayName}
        </MText>
        <MText block textVariant='small' textColor={MTextColor.shadow}>
          {explanation}
        </MText>
        <MFlexBlock gap={2} alignItems='center' margin={[0, 0, 2, 0.5]}>
          {loading ? (
            <CircularProgress size='20px' />
          ) : (
            <MText block textVariant='content' textColor='#000000de'>
              {`${t('Value')}: `}
              &nbsp;
              <span style={{ display: 'inline-block', minWidth: '50px', textAlign: 'center' }}>{settingValue}</span>
              <IconButton onClick={() => setEditDialogOpen(true)} sx={{ mt: '-0.3rem' }} disabled={loading}>
                <Icon path={mdiPencil} size={0.8} />
              </IconButton>
            </MText>
          )}
        </MFlexBlock>
      </MFlexBlock>

      {editDialogOpen && (
        <EditIntegerSettingDialog
          displayName={displayName}
          currentValue={settingValue}
          updateSettingMutationState={updateSettingState}
          onSave={handleDialogSave}
          onClose={() => setEditDialogOpen(false)}
        />
      )}
    </>
  )
}

const EditIntegerSettingDialog = ({
  displayName,
  currentValue,
  updateSettingMutationState,
  onSave,
  onClose,
}: {
  displayName: string
  currentValue: number | null
  updateSettingMutationState: MutationResult
  onSave: (values: { value: number }) => void
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const [initialValues] = useLocalState<{ value: number }>(
    () =>
      withFormikCompareFix({
        value: currentValue!,
      }),
    [currentValue],
  )

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSave}>
      <FormikDialog title={t('EditSetting')} submit={t('Save')} open onClose={onClose} minWidth='sm'>
        <SavingSwitchPanel mutation={updateSettingMutationState}>
          <MutationErrorMessage mutation={updateSettingMutationState} />
          <MText block style={{ margin: '8px' }}>
            {t(displayName)}
          </MText>
          <Field component={MFieldConnector} name='value' label={t('Value')}>
            <MFieldInteger />
          </Field>
        </SavingSwitchPanel>
      </FormikDialog>
    </Formik>
  )
}
