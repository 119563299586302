import { gql } from '@apollo/client'

export const GET_INSP_LOCATIONS = gql`
  query inspectLocations($filter: FarmFilter) {
    farms(filter: $filter) {
      id: internal
      name
    }
  }
`

export const GET_INSP_GENERA = gql`
  query inspectGenera {
    crops {
      id: internal
      name
    }
  }
`

export const GET_INSP_JOB_TYPES = gql`
  query inspectJobTypes($filter: JobTypeFilter) {
    jobTypes(filter: $filter) {
      id: internal
      name
    }
  }
`
