import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { MPageTitle } from '../../shared/page-title'
import { BooleanSetting, IntegerSetting } from '../../shared/settings'

export const InspectSettingsPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet title={`Inspect App - ${t('Settings')}`} />

      <MPageTitle label={`Inspect App - ${t('Settings')}`} />

      <br />

      <BooleanSetting
        settingName='enable-auto-sync'
        displayName={t('Turn auto-synchronization on')}
        explanation={t(
          'Set the preference to automatically synchronize Inspection Template data (excluding pictures) when there is an active connection to the internet.',
        )}
      />

      <BooleanSetting
        settingName='hide-sections-without-jobs'
        displayName={t('Hide sections without jobs')}
        explanation={t(
          'Set the preference to show or hide sections where there are no jobs visible within the given filter.',
        )}
      />

      <IntegerSetting
        settingName={'compression-size'}
        displayName={t('Compression size')}
        explanation={t('compressionSizeInfoText')}
      />
    </>
  )
}
