import { Route, Routes } from 'react-router-dom'
import { ActivityList } from './activity/page'
import { DashboardRoute } from './dashboard'
import { SettingsPage } from './settings/page'
import { ItemList } from './item/page'
import { JobList } from './job/page'
import { LocationList } from './location/page'
import { PositionList } from './position/page'
import { ResourceList } from './resource/page'
import { SkillDialog } from './skill/dialog'
import { SkillPage } from './skill/page'
import { TeamRoute } from './team/page'
import { TemplateDialog } from './work-item-template/dialog'
import { TrackingIdList } from './trackingid/page'
import { CarrierIdPage } from './carrierid/page'
import { LotPage } from './lots/page'
import { ContainersPage } from './containers/page'
import { SpacingMethodPage } from './spacing-methods/page'
import { ReasonsPage } from './reasons/page'
import { WarehouseStatusDialogRoute } from './tracking-id-status/dialog'
import { WarehouseStatusList } from './tracking-id-status/page'
import { WorkItemTemplateList } from './work-item-template/page'
import { ResourcePlanningGroupRoute } from './resource-planning-group/page'
import { TrackingDetailsDialogRoute } from './trackingid/details-dialog'
import { GHWorkItemTemplateList } from './greenhouse-template/page'
import { GHTemplateDialog } from './greenhouse-template/dialog'
import { AutoPickSettingsPage } from './auto-pick/page'
import { RolesList } from './roles/page'
import { UsersList } from './users/page'
import { UserDialog } from './users/dialog'
import { RoleDialog } from './roles/dialog'
import { GenusList } from './genus/page'
import { VariantList } from './variants/page'
import { AutoPickRuleDialog } from './auto-pick/dialog'
import { GreenhouseSettingsPage } from './greenhouse-settings/page'
import { InspectSettingsPage } from './inspect-settings/page'
import { AdditionalAttributeRulesPage } from './additional-attribute-rules/page'
import { AdditionalAttributeRulesDialog } from './additional-attribute-rules/dialog'

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<DashboardRoute />} />

        <Route path='/activities' element={<ActivityList />} />
        <Route path='/items' element={<ItemList />} />
        <Route path='/reasons' element={<ReasonsPage />} />
        <Route path='/jobs' element={<JobList />} />
        <Route path='/locations' element={<LocationList />} />
        <Route path='/positions' element={<PositionList />} />
        <Route path='/resources' element={<ResourceList />} />
        <Route path='/carrierids' element={<CarrierIdPage />} />
        <Route path='/trackingids' element={<TrackingIdList />} />
        <Route path='/trackingids/detail/:tridIdAndCode' element={<TrackingIdList />} />
        <Route path='/lots' element={<LotPage />} />
        <Route path='/genera' element={<GenusList />} />
        <Route path='/variants' element={<VariantList />} />
        <Route path='/containers' element={<ContainersPage />} />
        <Route path='/spacing-methods' element={<SpacingMethodPage />} />
        <Route path='/teams' element={<TeamRoute />} />
        <Route path='/tracking-id-status' element={<WarehouseStatusList />} />
        <Route path='/tracking-id-status/:action' element={<WarehouseStatusList />} />
        <Route path='/tracking-id-status/edit/:trackingIdStatusId' element={<WarehouseStatusList />} />
        <Route path='/resource-planning-groups' element={<ResourcePlanningGroupRoute />} />
        <Route path='/skills' element={<SkillPage />} />
        <Route path='/skills/new' element={<SkillPage />} />
        <Route path='/skills/edit/:skillId' element={<SkillPage />} />
        <Route path='/greenhouse-templates' element={<GHWorkItemTemplateList />} />
        <Route path='/greenhouse-templates/:action' element={<GHWorkItemTemplateList />} />
        <Route path='/greenhouse-templates/edit/:templateId' element={<GHWorkItemTemplateList />} />
        <Route path='/work-item-templates' element={<WorkItemTemplateList />} />
        <Route path='/work-item-templates/:action' element={<WorkItemTemplateList />} />
        <Route path='/work-item-templates/edit/:templateId' element={<WorkItemTemplateList />} />

        <Route path='/inspect/settings' element={<InspectSettingsPage />} />

        <Route path='/auto-pick' element={<AutoPickSettingsPage />} />
        <Route path='/auto-pick-rules/new' element={<AutoPickSettingsPage />} />
        <Route path='/auto-pick-rules/edit/:ruleId' element={<AutoPickSettingsPage />} />

        <Route path='additional-attribute-rules' element={<AdditionalAttributeRulesPage />} />
        <Route path='additional-attribute-rules/new' element={<AdditionalAttributeRulesPage />} />
        <Route path='additional-attribute-rules/edit/:ruleId' element={<AdditionalAttributeRulesPage />} />

        <Route path='/greenhouse/settings' element={<GreenhouseSettingsPage />} />

        <Route path='/users' element={<UsersList />} />
        <Route path='/users/new' element={<UsersList />} />
        <Route path='/users/edit/:userId' element={<UsersList />} />
        <Route path='/roles' element={<RolesList />} />
        <Route path='/roles/new' element={<RolesList />} />
        <Route path='/roles/edit/:roleId' element={<RolesList />} />

        <Route path='/settings' element={<SettingsPage />} />
      </Routes>
      <Routes>
        {/*
          This second Routes element causes the dialogs to be rendered with their respective pages still in the background.
          It does however create many 'No routes matched location <...>' warnings in the console...
        */}
        <Route path='/trackingids/detail/:tridIdAndCode' element={<TrackingDetailsDialogRoute />} />

        <Route path='/tracking-id-status/new' element={<WarehouseStatusDialogRoute />} />
        <Route path='/tracking-id-status/edit/:trackingIdStatusId' element={<WarehouseStatusDialogRoute />} />

        <Route path='/skills/new' element={<SkillDialog />} />
        <Route path='/skills/edit/:skillId' element={<SkillDialog />} />

        <Route path='/users/new' element={<UserDialog />} />
        <Route path='/users/edit/:userId' element={<UserDialog />} />

        <Route path='/roles/new' element={<RoleDialog />} />
        <Route path='/roles/edit/:roleId' element={<RoleDialog />} />

        <Route path='/greenhouse-templates/:action' element={<GHTemplateDialog />} />
        <Route path='/greenhouse-templates/edit/:templateId' element={<GHTemplateDialog />} />
        <Route path='/work-item-templates/:action' element={<TemplateDialog />} />
        <Route path='/work-item-templates/edit/:templateId' element={<TemplateDialog />} />

        <Route path='/auto-pick-rules/new' element={<AutoPickRuleDialog />} />
        <Route path='/auto-pick-rules/edit/:ruleId' element={<AutoPickRuleDialog />} />

        <Route path='additional-attribute-rules/new' element={<AdditionalAttributeRulesDialog />} />
        <Route path='additional-attribute-rules/edit/:ruleId' element={<AdditionalAttributeRulesDialog />} />
      </Routes>
    </>
  )
}
